import React from "react"
import { Box } from "theme-ui"

interface SubInfoBoxProps {
  children: React.ReactNode
}

const SubInfoBox = (props: SubInfoBoxProps): JSX.Element => {
  const { children } = props

  return (
    <Box
      px={3}
      py={[2, 3]}
      color="#555555"
      sx={{
        border: "1px solid #70707081",
        fontSize: [0, 3],
        textAlign: "center",
        marginTop: ["0px", "auto"],
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
      }}
      bg="#F4F4F4"
    >
      <Box sx={{ maxWidth: "900px", margin: "auto" }}>{children}</Box>
    </Box>
  )
}

export default SubInfoBox
