import React from "react"
import { Box, Text } from "theme-ui"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import SubInfoBox from "../../components/SubInfoBox"
import ContentSection from "../../components/ContentSection"
import SEO from "../../components/SEO"
import ReferenceLink from "../../components/UpscReference/ReferenceLink"

const links = [
  {
    text: "History of Modern World",
    href: "http://amzn.to/2nQP8Wy",
  },
  {
    text: "Social Problems In India",
    href: "http://amzn.to/2mTf0ju",
  },
  {
    text: "India After Gandhi",
    href: "http://amzn.to/2mrzmnR",
  },
  {
    text: "India and the World by Surendra Kumar",
    href: "http://amzn.to/2nQXsW8",
  },
  {
    text: "Pax Indica – Shashi Tharoor",
    href: "http://amzn.to/2nlF9KK",
  },
  {
    text: "Governance in India for CSE",
    href: "http://amzn.to/2nbZfXv",
  },
  {
    text: "IAS Mains General Studies Paper 2",
    href: "http://amzn.to/2nIm8TL",
  },
  {
    text: "India’s National Security: A Reader",
    href: "http://amzn.to/2nlGlhn",
  },
  {
    text: "Challenges to Internal Security of India",
    href: "http://amzn.to/2nc7QJR",
  },
  {
    text: "IAS Mains General Studies Paper 3",
    href: "http://amzn.to/2nlPUNo",
  },
  {
    text: "Ethics, Integrity and Aptitude for Civil Services Mains",
    href: "http://amzn.to/2ncbfIn",
  },
  {
    text: "CIVIL SERVICES INTERVIEW: HOW TO EXCEL",
    href: "http://amzn.to/2nQLThv",
  },
  {
    text: "Kit of Interviews",
    href: "http://amzn.to/2nccnM9",
  },
  {
    text:
      "Interviews The Last Basic Tips On facing Civil Services Personality Test",
    href: "http://amzn.to/2nQM2BJ",
  },
  {
    text: "Interview Cracker",
    href: "http://amzn.to/2ncajnp",
  },
  {
    text: "Contemporary Essays by Ramesh Singh",
    href: "http://amzn.to/2nc5mei",
  },
  {
    text: "151 ESSAYS For UPSC Mains",
    href: "http://amzn.to/2nIeWqM",
  },
  {
    text: "A Compendium of Essays",
    href: "http://amzn.to/2nQNbZW",
  },
]

const UpscReferencePage = (): JSX.Element => (
  <Box>
    <SEO title="UPSC Reference - Mains" />
    <Layout>
      <PageHeader>
        <Text sx={{ fontSize: 5, fontWeight: "bold", textAlign: "center" }}>
          UPSC Reference Material - Mains
        </Text>
      </PageHeader>
      <SubInfoBox>
        Get the latest UPSC Reference Material prepared by Sarkar IAS Academy
      </SubInfoBox>
      <ContentSection
        header="Quick links for UPSC Reference Material - Mains"
        noHeaderBorder={true}
        body={
          <Box mt={[-4,0]}>
            {links.map(link => (
              <ReferenceLink name={link.text} href={link.href} />
            ))}
          </Box>
        }
      />
    </Layout>
  </Box>
)

export default UpscReferencePage
