import React from "react"
import { Box, Flex, Link } from "theme-ui"
import { Download } from "@emotion-icons/boxicons-regular/Download"

interface ReferenceLinkProps {
  name: string
  href: string
}

const ReferenceLink = (props: ReferenceLinkProps) => {
  const { name, href } = props
  return (
    <Box
      mb={2}
      sx={{
        border: "1px solid #7070707B",
        borderRadius: "7px",
        boxShadow: 1,
      }}
      p={3}
    >
      <Flex sx={{  alignItems: "flex-end", cursor: "pointer" }}>
        <Link
          target="_blank"
          href={href}
          color="#249AD8"
          sx={{ textDecoration: "underline", fontSize:[0,1] }}
        >
          {name}
        </Link>
        <Box ml={3}>
          <Download color="#249AD8" size="1.6rem" />
        </Box>
      </Flex>
    </Box>
  )
}

export default ReferenceLink
