/** @jsx jsx */
import React from "react"
import { Box, Flex, jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

interface PageHeaderProps {
  children: React.ReactNode
}

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "headerBg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage Tag="section" fluid={data.bgImage.childImageSharp.fluid}>
      <Box sx={{ position: "relative" }} color="white" px={3} py={5}>
        {/* Black overlay */}
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            background: "rgba(0, 0, 0, 0.7)",
            zIndex: -1,
          }}
        />

        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>{props.children}</Box>
        </Flex>
      </Box>
    </BackgroundImage>
  )
}

export default PageHeader
